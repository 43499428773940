import React, { useState, useEffect } from 'react';
import ai from '../images/ai.png';
import chatbot from '../images/chatbot.png';
import chip from '../images/chip.png';
import database from '../images/database.png';
import dish from '../images/dish.png';
import gear from '../images/gear.png';
import railway from '../images/railway.png';
import table from '../images/table.png';
import tea from '../images/tea.png';
import Carousel from '../Carousel/Carousel';
import './Projects.css';

function Projects({ animationDirection }) {
  const [searchField, setSearchField] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  const projects = [
    { id: 1, name: 'Progetto a1', description: (<>Descrizione esempio esempio esempio esempio esempio esempio<br />s<br />e<br />m<br />p<br />i<br />o<br />e<br />s<br />e<br />m<br />p<br />i<br />o<br /> del Progetto 1 </>), image: ai },
    { id: 2, name: 'Progetto a2', description: 'Descrizione del Progetto 2', image: chatbot },
    { id: 3, name: 'Progetto a3', description: 'Descrizione del Progetto 3', image: chip },
    { id: 4, name: 'Progetto a4', description: 'Descrizione del Progetto 4', image: database },
    { id: 5, name: 'Progetto a5', description: 'Descrizione del Progetto 5', image: dish },
    { id: 6, name: 'Progetto b6', description: 'Descrizione del Progetto 6', image: gear },
    { id: 7, name: 'Progetto b7', description: 'Descrizione del Progetto 7', image: railway },
    { id: 8, name: 'Progetto b8', description: 'Descrizione del Progetto 8', image: table },
    { id: 9, name: 'Progetto b9', description: 'Descrizione del Progetto 9', image: tea },
  ];

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchField.toLowerCase())
  );

  useEffect(() => {
    if (filteredProjects.length === 0) {
      setCurrentIndex(0); // Reset index se non ci sono progetti
    } else if (currentIndex >= filteredProjects.length) {
      setCurrentIndex(0); // Reset index se l'indice supera i progetti filtrati
    }
  }, [filteredProjects, currentIndex]);

  return (
    <div className={`projects-section ${animationDirection === 'up' ? 'slide-up' : 'slide-down'}`}>
      <h2>Progetti</h2>
      <input
        type="text"
        placeholder="Cerca progetti..."
        className="search-bar"
        onChange={(e) => setSearchField(e.target.value)}
      />

      {filteredProjects.length === 0 ? (
        <p className="no-projects-found">Nessun progetto trovato.</p>
      ) : (
        <>
          <div className="projects-list">
            {filteredProjects.map((project) => (
              <div key={project.id} className="project-card">
                <h3>{project.name}</h3>
              </div>
            ))}
          </div>

          <Carousel
            currentProject={filteredProjects[currentIndex]}
            setCurrentIndex={setCurrentIndex}
            filteredProjects={filteredProjects}
          />
        </>
      )}
    </div>
  );
}

export default Projects;
