import './App.css';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header'; 
import Home from './components/Home/Home';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import { changeBackground } from './gestioneBG';


function App() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animationDirection, setAnimationDirection] = useState('');
  const footerRef = useRef(null); // Ref per il footer

  const sections = useMemo(() => ['/', '/about', '/projects', '/contact'], []);

  const handleNavClick = (newIndex) => {
    if (newIndex > activeIndex) {
      setAnimationDirection('up');
    } else if (newIndex < activeIndex) {
      setAnimationDirection('down');
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const section = sections[activeIndex] === '/' ? 'home' : sections[activeIndex].slice(1);
    console.log('Changing background for section:', section); // Log della sezione attiva
    changeBackground(section);
  }, [activeIndex, sections]);

  // Aggiungi questo useEffect per il caricamento iniziale
  useEffect(() => {
    console.log('Initial background change for home section');
    changeBackground('home'); // Cambia in base alla sezione iniziale
  }, []);
  
  useEffect(() => {
    const handleMouseMove = (event) => {
      const footer = footerRef.current;
      if (footer) {
        const footerHeight = footer.offsetHeight;
        const mouseY = event.clientY;
        if (mouseY >= window.innerHeight - footerHeight) {
          footer.classList.add('visible');
        } else {
          footer.classList.remove('visible');
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Router>
      <div className="App">
      <div className="backgroundanimation" />
        <Header handleNavClick={handleNavClick} sections={sections} activeIndex={activeIndex} />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home animationDirection={animationDirection} />} />
            <Route path="/about" element={<About animationDirection={animationDirection} />} />
            <Route path="/projects" element={<Projects animationDirection={animationDirection} />} />
            <Route path="/contact" element={<Contact animationDirection={animationDirection} />} />
          </Routes>
        </div>
        <Footer ref={footerRef} />
      </div>
    </Router>
  );
}

export default App;
