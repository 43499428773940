import React, { useEffect, useRef } from "react";
import setupCanvas from "./setupCanvas";
import './canvas.css';

const Canvas = (props) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    setupCanvas(canvas, context);

    // Clean up on unmount
    return () => {
      canvas.width = 0;
      canvas.height = 0;
    };
  }, []);

  return <canvas ref={canvasRef} {...props} />;
};

export default Canvas;