import React, { useEffect, useCallback } from 'react';
import './Carousel.css';

function Carousel({ currentProject, setCurrentIndex, filteredProjects }) {
  const totalProjects = filteredProjects.length;

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalProjects);
  }, [setCurrentIndex, totalProjects]);

  const handlePrevious = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalProjects) % totalProjects);
  }, [setCurrentIndex, totalProjects]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 6000);
    return () => clearInterval(interval);
  }, [handleNext]);

  if (!currentProject) {
    return null; // Non renderizzare nulla se currentProject è undefined
  }

  return (
    <div className="carousel">
      <div className="carousel-content">
        <button
          className="carousel-control-prev"
          type="button"
          onClick={handlePrevious}
          aria-label="Previous"
        >
          &lt;
        </button>

        <div className="carousel-inner">
          <img src={currentProject.image} alt={currentProject.name} className="carousel-image" />
        </div>

        <button
          className="carousel-control-next"
          type="button"
          onClick={handleNext}
          aria-label="Next"
        >
          &gt;
        </button>
      </div>

      <div className="project-info">
        <h3>{currentProject.name}</h3>
        <p>{currentProject.description}</p>
      </div>
    </div>
  );
}

export default Carousel;
