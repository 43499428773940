import React, { useState, Suspense } from 'react';
import './About.css';

// Lazy loading components
const Bio = React.lazy(() => import('./Bio'));
const Skills = React.lazy(() => import('./Skills'));
const WorkExperience = React.lazy(() => import('./WorkExperience'));
const Education = React.lazy(() => import('./Education'));

function About({ animationDirection }) {
  const [activeSlide, setActiveSlide] = useState('bio');

  const renderContent = () => {
    switch (activeSlide) {
      case 'skills':
        return <Skills />;
      case 'workExperience':
        return <WorkExperience />;
      case 'education':
        return <Education />;
      default:
        return <Bio />;
    }
  };

  return (
    <div className={`about-section ${animationDirection === 'up' ? 'slide-up' : 'slide-down'}`}>
      <div className="about-wrapper">
        <div className="left-column">
          <img src="/images/sg1.jpg" alt="Your Name" className="profile-photo" />
        </div>
        <div className="right-column">
          <nav className="slideshow-nav">
            <button onClick={() => setActiveSlide('bio')} className={activeSlide === 'bio' ? 'active' : ''}>
              Bio
            </button>
            <button onClick={() => setActiveSlide('workExperience')} className={activeSlide === 'workExperience' ? 'active' : ''}>
              Esperienze Lavorative
            </button>
            <button onClick={() => setActiveSlide('skills')} className={activeSlide === 'skills' ? 'active' : ''}>
              Competenze
            </button>
            <button onClick={() => setActiveSlide('education')} className={activeSlide === 'education' ? 'active' : ''}>
              Educazione
              </button>
            <a href="/images/CvStefanoGalassi.pdf" download>
              <button className="download-cv"><strong>CV.pdf</strong></button>
            </a>
          </nav>
          <div className="slideshow-content">
            <Suspense fallback={<div>Loading...</div>}>
              {renderContent()}
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
