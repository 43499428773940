const setupCanvas = (canvas, ctx) => {
  let innerWidth = window.innerWidth;
  let innerHeight = window.innerHeight;
  canvas.width = innerWidth;
  canvas.height = innerHeight;

  let particlesArray = [];

  let mouse = {
    x: null,
    y: null,
    radius: (canvas.height / 200) * (canvas.width / 200),
  };

  window.addEventListener("mousemove", (event) => {
    mouse.x = event.x;
    mouse.y = event.y;
  });

  class Particle {
    constructor(x, y, directionX, directionY, size, color) {
      this.x = x;
      this.y = y;
      this.directionX = directionX;
      this.directionY = directionY;
      this.size = size;
      this.color = color;
    }

    draw() {
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
      ctx.fillStyle = this.color;
      ctx.fill();
    }

    update() {
      if (this.x > canvas.width || this.x < 0) {
        this.directionX = -this.directionX;
      }
      if (this.y > canvas.height || this.y < 0) {
        this.directionY = -this.directionY;
      }

      let dx = mouse.x - this.x;
      let dy = mouse.y - this.y;
      let distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < mouse.radius + this.size) {
        if (mouse.x < this.x && this.x < canvas.width - this.size * 10) {
          this.x += 3;
        } else if (mouse.x > this.x && this.x > this.size * 10) {
          this.x -= 3;
        }
        if (mouse.y < this.y && this.y < canvas.height - this.size * 10) {
          this.y += 3;
        } else if (mouse.y > this.y && this.y > this.size * 10) {
          this.y -= 3;
        }
      }

      this.x += this.directionX;
      this.y += this.directionY;

      this.draw();
    }
  }

  function init() {
    particlesArray = [];
    let numberOfParticles;
  
    if (window.innerWidth <= 480) {
      numberOfParticles = 20; // Riduce il numero di particelle su schermi piccoli
    } else if (window.innerWidth <= 768) {
      numberOfParticles = 40; // Numero intermedio di particelle per schermi medi
    } else {
      numberOfParticles = 80; // Numero di particelle per schermi più grandi
    }
  
    for (let i = 0; i < numberOfParticles; i++) {
      let size = Math.random() * 5 + 1;
      let x = Math.random() * (window.innerWidth - size * 2) + size * 2; // window.innerWidth
      let y = Math.random() * (window.innerHeight - size * 2) + size * 2; // window.innerHeight
      let directionX = Math.random() * 1 - 0.5;
      let directionY = Math.random() * 1 - 0.5;
      let color = "#ddd5d5";
  
      particlesArray.push(
        new Particle(x, y, directionX, directionY, size, color)
      );
    }
  }

  function connect() {
    for (let a = 0; a < particlesArray.length; a++) {
      for (let b = a; b < particlesArray.length; b++) {
        let distance =
          (particlesArray[a].x - particlesArray[b].x) ** 2 +
          (particlesArray[a].y - particlesArray[b].y) ** 2;

        if (distance < (canvas.width / 7) * (canvas.height / 7)) {
          let opacityValue = 1 - distance / 20000;
          ctx.strokeStyle = `rgba(232, 232, 232, ${opacityValue})`;
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.moveTo(particlesArray[a].x, particlesArray[a].y);
          ctx.lineTo(particlesArray[b].x, particlesArray[b].y);
          ctx.stroke();
        }
      }
    }
  }

  function animate() {
    requestAnimationFrame(animate);
    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight); // window.innerWidth, window.innerHeight

    particlesArray.forEach(particle => particle.update());
    connect();
  }

  window.addEventListener("resize", () => {
    innerWidth = window.innerWidth;    
    innerHeight = window.innerHeight; 
    canvas.width = innerWidth;
    canvas.height = innerHeight;
    mouse.radius = (canvas.height / 80) * (canvas.height / 80);
    init();
  });

  window.addEventListener("mouseout", () => {
    mouse.x = undefined;
    mouse.y = undefined;
  });

  init();
  animate();
};

export default setupCanvas;
