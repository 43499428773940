import React, { useRef, useEffect } from 'react';
import './AudioPlayer.css';

const AudioPlayer = React.forwardRef(({ isPlaying, volume, onVolumeChange }, audioRef) => {
  const internalRef = useRef(null);
  const activeAudioRef = audioRef || internalRef; // Usa ref passato o quello interno

  useEffect(() => {
    if (activeAudioRef.current) {
      activeAudioRef.current.volume = volume; // Imposta il volume corrente
      if (isPlaying) {
        activeAudioRef.current.play();
      } else {
        activeAudioRef.current.pause();
      }
    }
  }, [isPlaying, volume, activeAudioRef]);

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    if (activeAudioRef.current) {
      activeAudioRef.current.volume = newVolume;
    }
    if (onVolumeChange) {
      onVolumeChange(newVolume); // Passa il nuovo volume al genitore
    }
  };

  return (
    <div className="audio-player">
      <input 
        type="range" 
        min="0" 
        max="1" 
        step="0.01" 
        value={volume} // Mostra il valore corrente della barra del volume
        onChange={handleVolumeChange} 
        aria-label="Volume control"
      />
      <audio ref={activeAudioRef} src="/sounds/spring-sunshine-piano-solo-ver-216052.mp3" />
    </div>
  );
});

export default AudioPlayer;
