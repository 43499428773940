let currentBgStart = 'rgb(2, 0, 139)'; // Default start color for Home
let currentBgEnd = 'rgb(117, 229, 240)'; // Default end color for Home
let activeTransition = null; // Variable to store the current transition

// Function to convert hex color to RGB
function hexToRgb(hex) {
  hex = hex.replace(/^#/, '');
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b];
}

function interpolateColors(startColor, endColor, factor) {
  const startRgb = hexToRgb(startColor);
  const endRgb = hexToRgb(endColor);

  if (!startRgb || !endRgb) {
    console.error('Invalid color values:', startColor, endColor);
    return startColor; // Fallback to starting color if interpolation fails
  }

  const r = Math.round(startRgb[0] + (endRgb[0] - startRgb[0]) * factor);
  const g = Math.round(startRgb[1] + (endRgb[1] - startRgb[1]) * factor);
  const b = Math.round(startRgb[2] + (endRgb[2] - startRgb[2]) * factor);

  return `rgb(${r}, ${g}, ${b})`;
}

export const changeBackground = (section) => {
  const root = document.documentElement;
  const newBgStart = getComputedStyle(root).getPropertyValue(`--${section}-bg-start`).trim();
  const newBgEnd = getComputedStyle(root).getPropertyValue(`--${section}-bg-end`).trim();

  // Log dei colori
  console.log('New Background Colors:', { newBgStart, newBgEnd });

  // Validazione dei valori dei colori
  if (!newBgStart || !newBgEnd) {
    console.error('Invalid color values from CSS variables:', newBgStart, newBgEnd);
    return;
  }

  const backgroundElement = document.querySelector('.backgroundanimation');
  if (!backgroundElement) return;

  const duration = 2500; // Durata della transizione in millisecondi
  const steps = 40; // Numero di passi per la transizione
  const interval = duration / steps; // Intervallo tra gli aggiornamenti in millisecondi
  let currentStep = 0; // Contatore di passi

  // Clear previous transition if exists
  if (activeTransition) {
    clearTimeout(activeTransition);
    activeTransition = null;
  }

  function updateBackground() {
    const factor = currentStep / steps;
    const interpolatedStart = interpolateColors(currentBgStart, newBgStart, factor);
    const interpolatedEnd = interpolateColors(currentBgEnd, newBgEnd, factor);
    
    backgroundElement.style.background = `linear-gradient(to bottom, ${interpolatedStart}, ${interpolatedEnd})`;

    console.log(`Transitioning Background - Step: ${currentStep}, Interpolated Start: ${interpolatedStart}, Interpolated End: ${interpolatedEnd}`);

    currentStep++;
    if (currentStep <= steps) {
      activeTransition = setTimeout(updateBackground, interval);
    } else {
      backgroundElement.style.background = `linear-gradient(to bottom, ${newBgStart}, ${newBgEnd})`;
      // Update current colors for the next transition
      currentBgStart = newBgStart;
      currentBgEnd = newBgEnd;
      activeTransition = null; // Clear active transition once done
      console.log('Background transition complete');
    }
  }

  updateBackground();
};
