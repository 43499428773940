import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header({ handleNavClick, sections, activeIndex }) {
  return (
    <header className="header">
      <nav className="nav">
        <ul className="nav-list">
          {sections.map((section, index) => (
            <li
              key={section}
              className={`nav-item ${index === activeIndex ? 'active' : ''}`} // Aggiungiamo la classe 'active'
            >
              <Link to={section} onClick={() => handleNavClick(index)}>
                {section === '/' ? 'Home' : section.slice(1).charAt(0).toUpperCase() + section.slice(2)}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
