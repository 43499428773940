import React, { useState } from 'react';
import './Contact.css';

function Contact({ animationDirection }) {
  const [formData, setFormData] = useState({
    name: '',
    mail: '',
    comment: ''
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Invia i dati al server
    fetch('/mail.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData).toString(),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Errore durante l\'invio del messaggio');
      }
      return response.text();
    })
    .then(data => {
      setMessage('Messaggio inviato con successo!');
      setError('');
      setFormData({ name: '', mail: '', comment: '' }); // Reset dei dati del modulo
    })
    .catch(err => {
      setError(err.message);
      setMessage('');
    });
  };

  return (
    <div className={`contact-section ${animationDirection === 'up' ? 'slide-up' : 'slide-down'}`}>
      <div className="contact-info-box">
        <h2>Contatto</h2>
        <p><strong>Telefono:</strong> 334 - 5724443</p>
        <p><strong>E-mail:</strong> stefano_47@hotmail.it</p>
      </div>
      <div className="contact-form-box">
        <h3>Modulo Email</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Nome:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Email:
              <input
                type="email"
                name="mail"
                value={formData.mail}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Commento:
              <textarea
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <button type="submit">Invia</button>
        </form>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}

export default Contact;
