import React, { useState, useRef } from 'react';
import Canvas from '../Particles/Canvas';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import './Home.css';

function Home({ animationDirection }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isWelcomeClicked, setIsWelcomeClicked] = useState(false);
  const [volume, setVolume] = useState(0.1); // Stato del volume iniziale
  const audioRef = useRef(null);

  const handleWelcomeClick = () => {
    setIsWelcomeClicked(!isWelcomeClicked);
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume); // Aggiorna lo stato del volume
  };

  return (
    <div className={`home-section ${animationDirection === 'up' ? 'slide-up' : 'slide-down'} `}>
      <Canvas className="particles" />
      <h1 
        onClick={handleWelcomeClick} 
        className={`welcome-text ${isWelcomeClicked ? 'clicked' : ''}`}
      >
        Welcome
      </h1>
      <AudioPlayer 
        isPlaying={isPlaying} 
        volume={volume} 
        onVolumeChange={handleVolumeChange} 
        ref={audioRef} 
      />
      <div className={`citation ${isWelcomeClicked ? 'show' : ''}`}>
        "On ne voit bien qu'avec le cœur. <br></br>L'essentiel est invisible pour les yeux."
        <br></br>
        <div className="citation2">Antoine de Saint-Exupéry</div>
      </div>
    </div>
  );
}

export default Home;
